<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Provisionamento / Onus</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>

        <div class="input-group mb-3">
          <input
              v-on:keyup.enter="handlePesquisa(true)"
              v-model="search.q"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button v-on:click="handlePesquisa(true)" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>

      <div class="d-flex">

        <!-- mobile -->
        <table v-if="shared.app.isMobile()" class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th>ONU</th>
              <th class="text-center">Sinal</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="onu in listaOnus" :key="onu.id" v-on:click="onuRowClickHandle(onu)" :class="cssRowOnu(onu)" class="cursor-pointer">
              <td scope="row">
                {{getOltDescription(onu.oltId)}} <strong>[{{onu.chassi}}/{{onu.slot}}/{{onu.ponPort}}:{{onu.index}}]</strong> <strong>{{onu.serialNumber}}</strong>
                <br>{{onu.description}} <span v-if="(onu.description2)">- {{onu.description2}}</span>
              </td>
              <td class="text-center">
                <Tooltip v-if="isPreActivated(onu)" position="left" :tooltipText="'Aprovação pendente'" class="me-1">
                  <span  v-if="isPreActivated(onu)" class="badge rounded-pill bg-dark">
                    <font-awesome-icon :icon="['fas', 'hourglass-half']" fixed-width class="text-warning"/>
                  </span>
                </Tooltip>
                <span v-if="!onuIsOnline(onu)">-</span>
                <span v-if="onuIsOnline(onu)">
                  {{formataSinalFibra(onu.signalOlt)}}
                  <br>
                  <span class="badge rounded-pill bg-primary">
                    Online
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- mobile -->

        <!-- desktop -->
        <table v-if="!shared.app.isMobile()" class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th width="110">OLT</th>
              <th class="text-center" width="70">Porta</th>
              <th class="text-center" width="36">ONU</th>
              <th width="115">Número de série</th>
              <th>Descrição</th>
              <th class="text-center" width="80">Sinal</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="onu in listaOnus" :key="onu.id" v-on:click="onuRowClickHandle(onu)" :class="cssRowOnu(onu)" class="cursor-pointer">
              <td scope="row"><span class="text-nowrap">{{getOltDescription(onu.oltId)}}</span></td>
              <td class="text-center">1/{{onu.slot}}/{{onu.ponPort}}</td>
              <td class="text-center">{{onu.index}}</td>
              <td><span class="text-nowrap">{{onu.serialNumber}}</span></td>
              <td>
                {{onu.description}} <span v-if="(onu.description2)">- {{onu.description2}}</span>
                <span class="float-end" style="margin-top: -2px;">
                  <span v-if="onuIsOnline(onu)" class="badge rounded-pill bg-primary">
                    Online
                  </span>
                  <Tooltip v-if="isPreActivated(onu)" position="left" :tooltipText="'Aprovação pendente'" class="ms-1">
                    <span  v-if="isPreActivated(onu)" class="badge rounded-pill bg-dark">
                      <font-awesome-icon :icon="['fas', 'hourglass-half']" fixed-width class="text-warning"/>
                    </span>
                  </Tooltip>
                </span>
              </td>
              <td class="text-center">
                <span v-if="!onuIsOnline(onu)">-</span>
                <span v-if="onuIsOnline(onu)">{{formataSinalFibra(onu.signalOlt)}}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- desktop -->

      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
      <div class="form-group">
        <font-awesome-icon v-if="(atualizandoSinal)" :icon="['fas', 'spinner']" fixed-width spin class="text-muted-2 ms-1"/>
      </div>
    </div>

    <!-- modal-onu-info -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-onu-info" id="btn-modal-onu-info-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-onu-info" tabindex="-1" role="dialog" aria-labelledby="modalOnuInfo" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div v-if="(modalOnu.onu)" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width/>
              Dados da ONU
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-md-6">
                <p class="mb-0"><span class="fw-bold">Identificação:</span> {{modalOnu.onu.description}}</p>
                <p class="mb-0"><span class="fw-bold">Ativação:</span> {{modalOnu.onu.created_at}}</p>
                <p class="mb-0"><span class="fw-bold">OLT:</span> {{getOltDescription(modalOnu.onu.olt_id)}}</p>
                <p class="mb-0"><span class="fw-bold">PON:</span> 1/{{modalOnu.onu.slot}}/{{modalOnu.onu.ponPort}}:{{modalOnu.onu.index}}</p>
                <p class="mb-0"><span class="fw-bold">Serial number:</span> {{modalOnu.onu.serialNumber}}</p>
                <p class="mb-0"><span class="fw-bold">VLAN:</span> {{modalOnu.onu.mainVlan}}</p>
                <p class="mb-0">
                  <span class="fw-bold">Sinal: </span>
                  <span v-if="!onuIsOnline(modalOnu.onu)">-</span>
                  <span v-if="onuIsOnline(modalOnu.onu)" :class="cssRowOnu(modalOnu.onu)">{{formataSinalFibra(modalOnu.onu.signalOlt)}}</span>
                </p>
              </div>

              <div class="col-12 col-md-6 pt-1">
                <IspOnuSignalRealtime v-if="modalOnu.onu" :onuId="modalOnu.onu.id"/>
              </div>

              <div class="col-12">
                <hr class="my-1">
              </div>

              <div class="col-12 mb-2">
                <div class="row g-0 gap-2">
                  <div class="col-12 col-sm-auto">
                    <div class="dropdown d-grid gap-2 d-md-block">
                      <button disabled class="btn btn-sm btn-secondary dropdown-toggle" type="button" id="triggerDDAcoesOnu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Informações
                      </button>
                      <ul class="dropdown-menu dropdown-menu-primary" aria-labelledby="triggerDDAcoesOnu">
                        <li>
                          <button v-on:click="modalInfoMudaPainel('status')" type="button" class="dropdown-item text-info">
                            <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width/> Status
                          </button>
                        </li>
                        <li>
                          <button v-on:click="modalInfoMudaPainel('configuracao')" type="button" class="dropdown-item text-info">
                            <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width/> Configuração
                          </button>
                        </li>
                        <li>
                          <button v-on:click="modalInfoMudaPainel('versao')" type="button" class="dropdown-item text-info">
                            <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width/> Versão
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-12 col-sm-auto d-grid gap-2 d-md-block">
                      <button v-on:click="modalInfoMudaPainel('renomear')" type="button" class="btn btn-sm btn-primary">
                        <font-awesome-icon :icon="['fas', 'pen']" fixed-width/> Renomear
                      </button>
                  </div>

                  <div class="col-12 col-sm-auto d-grid gap-2 d-md-block">
                      <button disabled v-on:click="modalInfoMudaPainel('modo-conexão')" type="button" class="btn btn-sm btn-primary">
                        <font-awesome-icon :icon="['fas', 'plug']" fixed-width/> Modo de conexão
                      </button>
                  </div>

                  <div class="col-12 col-sm-auto">
                    <div class="dropdown d-grid gap-2 d-md-block">
                      <button  class="btn btn-sm btn-danger dropdown-toggle" type="button" id="triggerDDAcoesOffOnu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Ações
                      </button>
                      <ul class="dropdown-menu dropdown-menu-primary" aria-labelledby="triggerDDAcoesOffOnu">
                        <li>
                          <button v-on:click="modalOnuDesativarOnuHandle(modalOnu.onu)" type="button" class="dropdown-item text-danger">
                            <font-awesome-icon :icon="['fas', 'undo']" fixed-width/> Desautorizar ONU
                          </button>
                        </li>
                        <li>
                          <button disabled v-on:click="modalOnuReiniciarOnuHandle(modalOnu.onu)" type="button" class="dropdown-item text-muted-2">
                            <font-awesome-icon :icon="['fas', 'power-off']" fixed-width/> Reiniciar ONU
                          </button>
                        </li>
                        <li>
                          <button disabled v-on:click="modalOnuResetOnuHandle(modalOnu.onu)" type="button" class="dropdown-item text-muted-2">
                            <font-awesome-icon :icon="['fas', 'hammer']" fixed-width/> Resetar configurações de fábrica
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- painel status -->
              <div v-if="modalOnu.painel === 'status'" class="col-12">
                <p class="mb-0 fw-bold">Status</p>
                <div class="form-group card card-primary p-2">
                  <font-awesome-icon v-if="(modalOnu.painelStatus.carregando)" :icon="['fas', 'spinner']" fixed-width spin class="align-self-center text-muted-2 my-4"/>
                  <pre v-if="(!modalOnu.painelStatus.carregando)">{{modalOnu.painelStatus.texto}}</pre>
                </div>
              </div>
              <!-- painel status -->

              <!-- painel configuracao -->
              <div v-if="modalOnu.painel === 'configuracao'" class="col-12">
                <p class="mb-0 fw-bold">Configuração</p>
                <div class="form-group card card-primary p-2">
                  <font-awesome-icon v-if="(modalOnu.painelConfiguracao.carregando)" :icon="['fas', 'spinner']" fixed-width spin class="align-self-center text-muted-2 my-4"/>
                  <pre v-if="(!modalOnu.painelConfiguracao.carregando)">{{modalOnu.painelConfiguracao.texto}}</pre>
                </div>
              </div>
              <!-- painel configuracao -->

              <!-- painel versao -->
              <div v-if="modalOnu.painel === 'versao'" class="col-12">
                <p class="mb-0 fw-bold">Versão</p>
                <div class="form-group card card-primary p-2">
                  <font-awesome-icon v-if="(modalOnu.painelVersao.carregando)" :icon="['fas', 'spinner']" fixed-width spin class="align-self-center text-muted-2 my-4"/>
                  <pre v-if="(!modalOnu.painelVersao.carregando)">{{modalOnu.painelVersao.texto}}</pre>
                </div>
              </div>
              <!-- painel versao -->

              <!-- painel renomear -->
              <div v-if="modalOnu.painel === 'renomear'" class="col-12">
                <div class="form-group">
                  <label for="" class="mb-0 fw-bold">Renomear ONU</label>
                  <div class="input-group mb-3">
                    <input v-model="modalOnu.form.description" type="text" class="form-control" placeholder="">
                    <button
                        v-on:click="modalOnuSalvarDescricao()"
                        v-bind:disabled="!modalOnuPodeSalvarOnu()"
                        type="button"
                        class="btn btn-success">
                      <font-awesome-icon :icon="['fas', 'check']" fixed-width/> Salvar
                    </button>
                    <button v-on:click="modalInfoMudaPainel('')" type="button" class="btn btn-outline-secondary">
                      <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
                    </button>
                  </div>
                </div>
              </div>
              <!-- painel renomear -->

              <!-- painel modo-conexão -->
              <div v-if="modalOnu.painel === 'modo-conexão'" class="col-12">
                <div class="form-group">
                  <label for="" class="mb-0 fw-bold">Modo de conexão</label>
                  <p class="mb-0 text-center">Aplicar PPPoE</p>
                  <div class="card p-2 d-grid gap-2">
                    <input v-model="modalOnu.form.pppoe_user" type="text" class="form-control text-center" placeholder="login">
                    <input v-model="modalOnu.form.pppoe_pass" type="text" class="form-control text-center" placeholder="senha">
                    <button
                        v-on:click="modalOnuInfoAplicaPPPoE()"
                        v-bind:disabled="!modalOnuInfoPodeSalvarPPPoE()"
                        type="button"
                        class="btn btn-success">
                      <font-awesome-icon :icon="['fas', 'check']" fixed-width/> Aplicar
                    </button>
                  </div>
                </div>
              </div>
              <!-- painel modo-conexão -->
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-onu-info-fecha">Fechar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-onu-info -->

    <IspOnuApprove :onuId="approveOnuId" :closeFunc="approveOnuClosed" />
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import shared from '../modules/shared'
import model from '@/model'
import Tooltip from "@/components/Tooltip.vue";
import IspOnuApprove from "@/components/IspOnuApprove.vue";
import IspOnuSignalRealtime from "@/components/IspOnuSignalRealtime.vue";

export default {
  name: 'Onus',

  components: {
    Paginador,
    Tooltip,
    IspOnuApprove,
    IspOnuSignalRealtime,
  },

  data() {
    return {
      atualizandoSinal: undefined,
      intervaloAtualizaSinal: undefined,
      listaOlts: [],
      listaOnus: [],
      listaPerfisBridge: [],
      listaPerfisRoteada: [],
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      approveOnuId: undefined,
      search: {
        q: '',
      },
      modalOnu: {
        onu: undefined,
        listaVlans: [],
        painel: '',
        painelStatus: {
          texto: '',
          carregando: false,
        },
        painelConfiguracao: {
          texto: '',
          carregando: false,
        },
        painelVersao: {
          texto: '',
          carregando: false,
        },
        form: {
          description: '',
          pppoe_user: '',
          pppoe_pass: '',
        },
      },
    }
  },

  methods: {
    carregaOlts() {
      const apiUrl =
          `/isp/olts?paginate.cancel`;

      api.get(apiUrl).then(res => {
        this.listaOlts = res.data.items;
      }).catch(() => {
        setTimeout(this.carregaOlts, 2000);
      });
    },

    carregaPerfis() {
      this.listaPerfisBridge = [
        {
          id: 1,
          modelo_olt: "ZYXEL_OLT2406",
          bridge: true,
          router: false,
          modelos_compativeis: ["*"],
          description: "GENÉRICA (bridge)",
        },
      ];

      this.listaPerfisRoteada = [
        {
          id: 2,
          modelo_olt: "ZYXEL_OLT2406",
          bridge: false,
          router: true,
          modelos_compativeis: ["*"],
          description: "GENÉRICA (router)",
        },
      ];
    },

    handlePesquisa(resetPage) {
      let params = this.paginador.requestParams;
      if (resetPage) {
        params = this.paginador.requestParamsFirst;
      }
      const apiUrl = `/isp/onus?${params}&q=${this.search.q}&orderBy[id]=desc`;

      loading(true);
      api.get(apiUrl).then(res => {
        loading(false);

        this.listaOnus = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    atualizaSinal() {
      const _vm = this;

      const ids = this.listaOnus.map(x => {
        return x.id.toString();
      });

      if (!ids || !ids.length) {
        return;
      }

      const apiUrl =
          `/isp/onus?id[in]=${ids}`;

      this.atualizandoSinal = true;
      api.get(apiUrl).then(res => {
        _vm.listaOnus.forEach(y => {
          res.data.itens.forEach(x => {
            if (x.id === y.id) {
              y.signalOlt = x.signalOlt;
            }
          });
        });

        this.atualizandoSinal = false;
      }).catch(() => {
        this.atualizandoSinal = false;
      });
    },

    onuIsOnline(onu) {
      return (!!onu.signalOlt);
    },

    isPreActivated(onu) {
      return (!onu.mainVlan);
    },

    cssRowOnu(onu) {
      const sinalCriticoAlto = -1600;
      const sinalAlertaAlto = -1700;
      const sinalAlertaBaixo = -2800;
      const sinalCriticoBaixo = -3000;
      const isOnline = this.onuIsOnline(onu);

      switch (true) {
        case (isOnline && (onu.signalOlt < sinalCriticoBaixo || onu.signalOlt > sinalCriticoAlto)):
          return 'text-danger';

        case (isOnline && (onu.signalOlt < sinalAlertaBaixo || onu.signalOlt > sinalAlertaAlto)):
          return 'text-warning';

        case (isOnline):
          return 'text-info';

        default:
          return 'text-muted';
      }
    },

    getOltDescription(id) {
      if (!id) {
        return '-';
      }

      const olt = this.listaOlts.find(x => (x.id === id));

      if (!olt) {
        return '-';
      }

      return olt.description;
    },

    /////////////////////////////////////
    // modal-onu-info
    async approveOnuClosed() {
      loading(true);
      await api.get(`/isp/onus/${this.approveOnuId}`).then(res => {
        loading(false);

        for (let i = 0; i < this.listaOnus.length; i++) {
          const element = this.listaOnus[i];
          if (element.id === res.data.id) {
            this.listaOnus[i] = res.data;
          }
        }
      }).catch(error => {
        loading(false);
        if (error.response && error.response.status === 404) {
          for (let i = 0; i < this.listaOnus.length; i++) {
            if (this.listaOnus[i].id === this.approveOnuId) {
              this.listaOnus.splice(i, 1);

              break
            }
          }

          return;
        }
        dialogo.erroHttp(error);
      });

      // TODO: ler e aplicar alterações
      this.approveOnuId = undefined;
    },
    onuRowClickHandle(onu) {
      switch (true) {
        case (!onu.mainVlan):
          this.approveOnuId = onu.id;
          break;

        default:
          this.modalOnuReset();
          this.modalOnu.onu = clone(onu);
          this.modalOnuAbre();
          this.modalOnuCarregaVlans();
      }
    },

    modalOnuAbre() {
      document.getElementById('btn-modal-onu-info-abre').click();
    },

    modalOnuFecha() {
      document.getElementById('btn-modal-onu-info-fecha').click();
    },

    modalOnuReset() {
      this.modalOnu.onu = undefined;
      this.modalOnu.painel = '';
      this.modalOnu.form.description = '';
      this.modalOnu.form.pppoe_user = '';
      this.modalOnu.form.pppoe_pass = '';
    },

    modalOnuCarregaVlans() {
      // const oltID = this.modalOnu.onu.olt_id;
      // const apiUrl = `/servidores/olt/${oltID}/vlans?paginate.cancel`;

      // loading(true);
      // api.get(apiUrl).then(res => {
      //   loading(false);

      //   this.modalOnu.listaVlans = res.data.items;
      // }).catch(error => {
      //   loading(false);
      //   dialogo.erroHttp(error);
      // });
    },

    modalInfoMudaPainel(painel) {
      if (this.modalOnu.painel === painel) {
        this.modalOnu.painel = '';
        return;
      }

      this.modalOnu.painel = painel;

      switch (painel) {
        case 'status':
          this.modalOnuInfoAtualizaStatus();
          break;

        case 'configuracao':
          this.modalOnuInfoAtualizaConfiguracao();
          break;

        case 'versao':
          this.modalOnuInfoAtualizaVersao();
          break;

        case 'renomear':
          this.modalOnu.form.description = this.modalOnu.onu.description;
          break;
      }
    },

    modalOnuInfoAtualizaStatus() {
      const onuID = this.modalOnu.onu.id;
      const apiUrl = `/isp/onu/${onuID}/integra/status`;

      this.modalOnu.painelStatus.carregando = true;
      api.get(apiUrl).then(res => {
        this.modalOnu.painelStatus.texto = res.data.string;
        this.modalOnu.painelStatus.carregando = false;
      }).catch(error => {
        dialogo.erroHttp(error);
      });
    },

    modalOnuInfoAtualizaConfiguracao() {
      const onuID = this.modalOnu.onu.id;
      const apiUrl = `/isp/onu/${onuID}/integra/configuracao`;

      this.modalOnu.painelConfiguracao.carregando = true;
      api.get(apiUrl).then(res => {
        this.modalOnu.painelConfiguracao.texto = res.data.string;
        this.modalOnu.painelConfiguracao.carregando = false;
      }).catch(error => {
        dialogo.erroHttp(error);
      });
    },

    modalOnuInfoAtualizaVersao() {
      const onuID = this.modalOnu.onu.id;
      const apiUrl = `/isp/onu/${onuID}/integra/versao`;

      this.modalOnu.painelVersao.carregando = true;
      api.get(apiUrl).then(res => {
        this.modalOnu.painelVersao.texto = res.data.string;
        this.modalOnu.painelVersao.carregando = false;
      }).catch(error => {
        dialogo.erroHttp(error);
      });
    },

    modalOnuPodeSalvarOnu() {
      if (this.modalOnu.form.description.length > 0) {
        return true;
      }

      return false;
    },

    modalOnuSalvarDescricao() {
      const apiUrl = `/isp/onus/${this.modalOnu.onu.id}`;

      const sendData = {
        description: this.modalOnu.form.description,
      };

      loading(true);
      api.patch(apiUrl, sendData).then(() => {
        loading(false);
        this.handlePesquisa();
        this.modalOnuFecha();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    modalOnuDesativarOnuHandle(onu) {
      dialogo.confirmacao(`Confirma desautorizar a ONU <b>${onu.serialNumber}</b>?`).then(() => {
        const apiUrl = `/isp/onus/${onu.id}`;

        loading(true);

        api.delete(apiUrl).then(() => {
          loading(false);
          this.handlePesquisa();
          this.modalOnuFecha();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    modalOnuReiniciarOnuHandle(onu) {
      dialogo.confirmacao(`Confirma reinciar a ONU <b>${onu.serialNumber}</b>?`).then(() => {
        const apiUrl = `/isp/onu/${onu.id}/integra/reboot`;

        loading(true);

        api.post(apiUrl).then(() => {
          loading(false);
          dialogo.alerta('Comando de reiniciar enviado com sucesso. Aguardae até que a operação esteja concluída.');
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    modalOnuResetOnuHandle(onu) {
      dialogo.confirmacao(`Confirma resetar a ONU <b>${onu.serialNumber}</b> para as configurações de fábrica?`).then(() => {
        const apiUrl = `/isp/onu/${onu.id}/integra/reset-config`;

        loading(true);

        api.post(apiUrl).then(() => {
          loading(false);
          dialogo.alerta('Comando de resetar configuração enviado com sucesso. Aguardae até que a operação esteja concluída.');
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    modalOnuAtivarOnuHandle(onu) {
      dialogo.confirmacao(`Confirma efetuar a ativação da ONU <b>${onu.serialNumber}</b>?`).then(() => {
        const apiUrl =
            `/isp/onu/${onu.id}`;

        const sendData = {
          estagio: 'oeAtivado',
        };

        loading(true);

        api.patch(apiUrl, sendData).then(() => {
          loading(false);

          this.handlePesquisa();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    modalOnuInfoAplicaPPPoE() {
      dialogo.confirmacao(`Confirma aplicar PPPoE nessa ONU?`).then(() => {
        const apiUrl = `/isp/onu/${this.modalOnu.onu.id}/pppoe`;

        const sendData = {
          login: this.modalOnu.form.pppoe_user,
          senha: this.modalOnu.form.pppoe_pass,
        };

        loading(true);

        api.post(apiUrl, sendData).then(() => {
          loading(false);

          this.handlePesquisa();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    modalOnuInfoPodeSalvarPPPoE() {
      return (this.modalOnu.form.pppoe_user && this.modalOnu.form.pppoe_pass);
    },
    // modal-onu-info
    /////////////////////////////////////




    // TODO: REFATORAR ESTA FUNÇÃO
    formataSinalFibra(sinal) {
      if ((!sinal && sinal !== 0) || sinal === -7000) {
        return '';
      }

      const s = sinal.toString();

      switch (true) {
        case sinal === 0:
          return '0.00';

        case sinal > 0 && sinal <= 9:
          return `0.0${s[0]}`;

        case sinal > 0 && sinal <= 99:
          return `0.${s[0]}${s[1]}`;

        case sinal > 0 && sinal <= 999:
          return `${s[0]}.${s[1]}${s[2]}`;

        case sinal > 0:
          return `${s[0]}${s[1]}.${s[2]}${s[3]}`;

        case sinal < 0 && sinal >= -9:
          return `-0.0${s[1]}`;

        case sinal < 0 && sinal >= -99:
          return `-0.${s[1]}${s[2]}`;

        case sinal < 0 && sinal >= -999:
          return `-${s[1]}.${s[2]}${s[3]}`;

        default:
          return `-${s[1]}${s[2]}.${s[3]}${s[4]}`;
      }
    },
  },

  computed: {
    shared() {
      return shared
    },
  },


  created() {
    this.carregaOlts();
    this.handlePesquisa();
    this.carregaPerfis();
    //this.carregaVlans();
    this.intervaloAtualizaSinal = setInterval(this.atualizaSinal, 10000);
  },

  mounted() {
    const _vm = this;
    const myModalEl = document.getElementById('modal-onu-info');

    myModalEl.addEventListener('hide.bs.modal', function () {
      _vm.modalOnu.onu = undefined;
    });
  },

  destroyed() {
    clearInterval(this.intervaloAtualizaSinal);
  },
}
</script>
